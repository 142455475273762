import React from "react";
import "./secondbanner.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
export default function SecondBanner() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`second-banner-section ${
        isVisible ? "section-animation" : ""
      }`}
      ref={ref}
    >
      <div className="container">
        <div className="second-banner-section-wrapper">
          <div className="second-banner-section-heading">
            <h2>Transform Your Operations with AutonomousOps AI</h2>
            <p>
              Seamlessly integrate AI agents to enhance efficiency and drive
              operational excellence.
            </p>
            <a href={config.bookdemo.Link}>
              <div className="second-banner-button">
                <p>Book a Demo</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
